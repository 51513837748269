export function getUserType(keycloakState) {
    if (
      keycloakState?.authenticated === true &&
      JSON.parse(sessionStorage.getItem("userSession"))?.isAdmin
    ) {
      return "admin";
    } else if (
      keycloakState?.authenticated === true &&
      JSON.parse(sessionStorage.getItem("userSession"))?.isChamberUser
    ) {
      return "chamberUser";
    }
  }
  
  export function getUserPermissions() {
      if (sessionStorage.getItem("roles")) {
        let role = JSON.parse(sessionStorage.getItem("roles")).filter(
          (x) => x == process.env.administratorRole
        );
        if (role.length > 0) {
          return "roleEnabled";
        } else {
          return "noRoleEnabled";
        }
      } else {
        return;
      }
    }